<template>
  <div class="viewer-tree">
    <NavInfo class="nav-info">
      <template slot="navigation">
        <NavItem @click="goBack">
          {{$t('pages.graph.navBar.back').toUpperCase()}}
        </NavItem>
        <NavItem :selected="selectedTopo === 'classic'"
                 @click="setClassicTopo">
          {{$t('pages.graph.navBar.space')}}
        </NavItem>
        <NavItem :selected="selectedTopo === 'energy'"
                 @click="setEnergyTopo">
          {{$t('pages.graph.navBar.energy')}}
        </NavItem>
      </template>
    </NavInfo>
    <ChartTree v-if="topologyIsAvailable"
               :nodes="topology"
               nodeClass="sm"
               :onDestroyNode="onNodeDestroyed" />
  </div>
</template>

<script>
import NavInfo from '@/app/components/layout/nav-info';
import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import ChartTree from '@/app/components/topology/chart-tree.vue';

export default {
  name: 'GraphViewer',
  components: {
    ChartTree,
    NavInfo,
    NavItem,
  },
  data() {
    return {
      selectedTopo: 'classic',
    };
  },
  computed: {
    topology() {
      return this.$store.state.topology.root;
    },
    topologyIsAvailable() {
      return this.topology && !this.$store.state.topology.fetching;
    },
    selectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    isFetching() {
      return this.$store.state.topology.fetching;
    },
  },
  created() {
    this.setClassicTopo();
  },
  methods: {
    setClassicTopo() {
      if (this.isFetching) return;
      this.$store.dispatch('topology/fetch');
      this.selectedNode = null;
      this.selectedTopo = 'classic';
    },
    setEnergyTopo() {
      if (this.isFetching) return;
      this.$store.dispatch('topology/fetchEnergy');
      this.selectedNode = null;
      this.selectedTopo = 'energy';
    },
    onNodeDestroyed(node) {
      if (!this.selectedNode) return;
      if (node.uuid === this.selectedNode.uuid) this.$store.commit('topology/setSelectedNode', null);
    },
    goBack() {
      this.$store.commit('ui/SET_VISUALISATION_MODE', '2d');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.viewer-tree
  display flex
  flex-direction column
  padding 0.1px // Forbid child margin to overlap
  width 100%
  height @width
  background-color $silver
  .nav-info
    margin $space-2 $space-2 0
</style>