<template>
  <li class="node-list"
      :class="listClass">
    <div class="node-container"
         :class="nodeClass"
         :draggable="node.nodeType === 'sensor'"
         @dragstart="dragStart"
         @dragend="dragEnd"
         @drop="drop"
         @dragover="dragOver"
         @dragleave="hover=false">
      <div :class="nodeItemClass"
           class="node"
           @click.stop="onClicked">
        <div class="icon">
          <icon-node-type :node="node"
                          :nodeClass="nodeClass" />
        </div>
        <div class="node-label-container">
          <span class="noselect node-label"
                :title="node.name">
            {{ node.name }}
          </span>
          <span class="noselect node-sub-label">
            {{ node && node.description || 'empty_description' }}
          </span>
        </div>
      </div>
      <button v-if="level > 0 && node.next && node.next.length > 0"
              class="circular"
              style="transition:none"
              @click.stop="onOpen">
        <q-icon v-if="open && parentOpen"
                name="mdi-minus" />
        <q-icon v-else
                name="mdi-plus" />
      </button>
    </div>
    <ul v-if="node.next && (level <= 0 || (open && parentOpen))"
        class="open">
      <tree-node v-for="n in node.next"
                 :key="n.uuid"
                 :node="n"
                 :onClick="onChildClicked"
                 :onNodeToggle="onNodeToggled"
                 :level="level+1"
                 :parentOpen="level <= 0 || open"
                 :nodeClass="nodeClass"
                 :currentPath="currentPath"
                 :parent="node"
                 :onDestroyNode="onDestroyNode">
      </tree-node>
    </ul>
  </li>
</template>

<script>
import _ from 'lodash';
import { EventBus } from 'src/event-bus.js';

import IconNodeType from '@/app/components/topology/icon-node-type.vue';

export default {
  name: 'TreeNode', // Needed for recursive call
  components: { IconNodeType },
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 0,
    },
    nodeClass: {
      type: String,
      default: 'nsm',
    },
    currentPath: {
      type: String,
      default: null,
    },
    parent: {
      type: Object,
      default: () => {},
    },
    parentOpen: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onNodeToggle: {
      type: Function,
      default: () => {},
    },
    onDestroyNode: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      hover: false,
      chartType: 'logical',
      allowedDropNodeTypes: [],
    };
  },
  computed: {
    selectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    listClass() {
      return {
        open: this.cssClassOpen,
      };
    },
    nodeItemClass() {
      return {
        selected: this.isSelected,
        last: this.node.nodeType === 'sensor',
        drop: this.dropAllowed,
        dropping: this.hover,
      };
    },
    open: {
      get: function() {
        return this.node && this.node.open;
      },
      set: function(newVal) {
        this.$set(this.node, 'open', newVal);
      },
    },
    cssClassOpen: {
      get: function() {
        return this.node && this.node.cssClassOpen;
      },
      set: function(newVal) {
        this.$set(this.node, 'cssClassOpen', newVal);
      },
    },
    isSelected() {
      if (!this.selectedNode || !this.node) return false;
      if (this.selectedNode.nodeType === 'device') return this.selectedNode.uuid === this.node.uuid;
      return this.selectedNode === this.node;
    },
    dropAllowed() {
      return this.allowedDropNodeTypes && this.node && this.allowedDropNodeTypes.indexOf(this.node.nodeType) !== -1;
    },
  },
  watch: {
    parentOpen: function(newParentOpen) {
      if (!newParentOpen) this.open = false;
    },
    currentPath: function(newCurrentPath) {
      this.openPath(newCurrentPath);
    },
  },
  created() {
    if (this.level <= 0) this.open = true;
    this.cssClassOpen = false;
    setTimeout(() => {
      this.cssClassOpen = true;
    }, 100);

    this.openPath(this.currentPath);

    EventBus.$on('node-drag-stop', () => {
      this.hover = false;
    });
  },
  destroyed() {
    this.onDestroyNode(this.node);
  },
  methods: {
    openPath(path) {
      if (!path) return;

      const found = _.find(path, pathNode => {
        return pathNode.uuid === this.node.uuid;
      });
      if (found) {
        // console.log(`found ${found.name}`);
        this.open = path.indexOf(found) < path.length - 1;
        this.onNodeToggle(this.node, this.open, this.level);
      } else if (this.open) {
        // console.log(`Not found ${this.node.name}`);
        this.open = false;
      }
    },
    onClicked(event) {
      this.onChildClicked(this.node);
    },
    onChildClicked(node) {
      this.onClick(node);
    },
    onOpen(event) {
      this.open = !this.open;
      this.onNodeToggle(this.node, this.open, this.level);
    },
    onNodeToggled(node, value, level) {
      this.onNodeToggle(node, value, level);
    },
    drop(ev) {
      ev.preventDefault();
      // const movedNode = JSON.parse(ev.dataTransfer.getData('text/plain'));
      // console.log(`Move ${movedNode.id} to ${this.node._id}`);
      // try {
      //   await api.topology.move(movedNode.parentId, this.node._id, movedNode.id);
      //   await this.$store.dispatch('getTopology');
      // } catch (e) {
      //   console.error(e);
      // }
    },
    dragStart(ev) {
      const data = { id: this.node._id, parentId: this.parent._id };
      ev.dataTransfer.setData('text/plain', JSON.stringify(data));
      EventBus.$emit('node-drag-start');
      // try {
      //   await this.$store.dispatch('getAllowedDropNodeTypes', {
      //     nodeType: this.node.nodeType,
      //     chartType: this.chartType,
      //   });
      // } catch (e) {
      //   console.error(e);
      // }
    },
    dragOver(ev) {
      if (this.dropAllowed) {
        ev.dataTransfer.dropEffet = 'move';
        this.hover = true;
        ev.preventDefault();
      } else {
        ev.dataTransfer.dropEffet = 'none';
        return false;
      }
    },
    dragEnd() {
      // this.$store.dispatch('clearAllowedDropNodeTypes');
      EventBus.$emit('node-drag-stop');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

ul, li
  max-height 0
  opacity 0

.node-list
  display flex
  align-items center
  .node-container
    position relative
    display flex
    align-items center
    width fit-content

.open
  max-height none
  opacity 1

// ============== NODE ==============
.node
  display flex
  align-items center
  padding-right $space-2
  padding-left $space-1
  min-width 200px
  height 50px
  border 1px solid black
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-elevation-1
  text-decoration none
  .icon
    display flex
    justify-content center
    align-content center
    margin-right $space-1
    width 25px
    color $dark
  .node-label-container
    display flex
    flex-direction column
    justify-content center
    align-items flex-start
    overflow hidden
    width 100%
    height 100%
    .node-label
      display flex
      margin 0
      margin-right $space-1
      padding-left $space-little
      width fit-content
      height 100%
      color black
      text-overflow ellipsis
      white-space nowrap
      font-weight 700
      font-size $fs-h3
      user-select none
    .node-sub-label
      overflow hidden
      margin 0
      width 100%
      height 100%
      color $grey
      text-overflow ellipsis
      white-space nowrap
      font-size $fs-body
      user-select none

// ========== NODE MODIFIERS ==========
.node
  &.drop
    border 1px solid $positive
    &.dropping
      background-color lighten($positive, 80%)
  &.selected
    background-color black
    color white
    .icon
      color $dark-pink
    .node-label-container
      .node-label
        color white
      .node-sub-label
        color $silver
  &.last
    min-width 150px
    width auto

// ========== OPEN BUTTON ==========
$button_open_size = 24px
$button_open_icon_size = 16px

button.circular
  position absolute
  right -($button_open_size / 2)
  z-index 1
  display flex
  justify-content center
  align-items center
  margin 0
  min-width $button_open_size
  min-height $button_open_size
  width $button_open_size
  height $button_open_size
  outline none
  border unset
  border-radius 50%
  background-color black
  color white
  user-select none
  .q-icon
    min-width $button_open_icon_size
    min-height $button_open_icon_size
    width $button_open_icon_size
    height $button_open_icon_size
    font-size $button_open_icon_size

.node.selected + button.circular
  background-color white
  .q-icon
    color black

// ========== SIZE: SMALL ==========
$xs_button_open_size = 15px
$xs_button_open_icon_size = 10px

.nxs
  .node
    padding 2px
    min-width 100px
    height 20px
    .icon
      margin-right $space-little
    .node-label-container
      .node-label
        font-size 9px
      .node-sub-label
        display none
  button.circular
    right -($xs_button_open_size / 2)
    padding 0
    min-width $xs_button_open_size
    min-height $xs_button_open_size
    width $xs_button_open_size
    height $xs_button_open_size
    border-radius 50%
    .q-icon
      font-size $xs_button_open_icon_size

// ========== SIZE: MEDIUM ==========
$sm_button_open_size = 20px
$sm_button_open_icon_size = 16px

.nsm
  .node
    padding 4px
    min-width 150px
    height 30px
    .icon
      margin-right $space-little
    .node-label-container
      .node-sub-label
        display none
  button.circular
    right -($sm_button_open_size / 2)
    min-width $sm_button_open_size
    min-height $sm_button_open_size
    width $sm_button_open_size
    height $sm_button_open_size
    .q-icon
      font-size $sm_button_open_icon_size

// ========== SIZE: LARGE ==========
.nmd
  .node
    .icon
      margin-right $space-1
    .node-label-container
      .node-label
        align-items flex-end
      .node-sub-label
        display flex
        align-items flex-start
        height 100% // width auto
  button.circular
    right -($sm_button_open_size / 2)
    min-width $sm_button_open_size
    min-height $sm_button_open_size
    width $sm_button_open_size
    height $sm_button_open_size
    .q-icon
      font-size $sm_button_open_icon_size
</style>