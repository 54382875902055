<template>
  <div class="tree-controls">
    <div class="control-group t_control-group--size">
      <div class="group__title">
        {{$t('pages.graph.tools.graphSize').toUpperCase()}}
      </div>
      <div class="group__main">
        <div class="size-select">
          <div class="size-select__item t_size-select__item--s"
               :class="sizeSelectItemClass(0)"
               @click="changeSize(0)">S</div>
          <div class="size-select__item t_size-select__item--m"
               :class="sizeSelectItemClass(1)"
               @click="changeSize(1)">M</div>
          <div class="size-select__item t_size-select__item--l"
               :class="sizeSelectItemClass(2)"
               @click="changeSize(2)">L</div>
        </div>
      </div>
    </div>
    <div class="control-group t_control-group--drag">
      <div class="group__title">
        {{$t('pages.graph.tools.drag').toUpperCase()}}
      </div>
      <div class="group__main">
        <q-checkbox v-model="dragEnabled"
                    color="white" />
      </div>
    </div>
    <div class="control-group t_control-group--center">
      <div class="group__title">
        {{$t('pages.graph.tools.center').toUpperCase()}}
      </div>
      <div class="group__main">
        <q-icon name="mdi-image-filter-center-focus"
                class="icon"
                size="20px"
                @click.native="$emit('center')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSizeChange: {
      type: Function,
      default: () => {},
    },
    onDragToggle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      size: 1,
      minSize: 0,
      maxSize: 2,
      dragEnabled: true,
    };
  },
  watch: {
    size: function(newSize) {
      this.onSizeChange(newSize);
    },
    dragEnabled: function(newDrag) {
      this.onDragToggle(newDrag);
    },
  },
  created() {
    this.onDragToggle(this.dragEnabled);
    this.onSizeChange(this.size);
  },
  methods: {
    sizeSelectItemClass(size) {
      return {
        'size-select__item--selected': this.size === size,
      };
    },
    changeSize(size) {
      this.size = size;
      this.onSizeChange(size);
    },
    increaseSize() {
      const desiredSize = this.size + 1;
      const maxSize = this.maxSize;
      this.size = desiredSize >= maxSize ? maxSize : desiredSize;
    },
    decreaseSize() {
      const desiredSize = this.size - 1;
      const minSize = this.minSize;
      this.size = desiredSize < minSize ? minSize : desiredSize;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.tree-controls
  z-index 2
  display flex
  padding 0
  border-radius $border-radius-smooth
  background-color black
  box-shadow $shadow-elevation-2
  .control-group
    display flex
    flex-direction column
    padding 0 $space-1
    border-right 1px solid $dark
    &:last-child
      border-right unset
    .group__title
      padding $space-little 0
      color $silver
      text-align center
      letter-spacing 1.5px
      font-size 0.8rem
    .group__main
      display flex
      justify-content center
      align-items center
      padding $space-little 0
      color white
    &--drag .group__main
      padding-top 1px
    &--center .group__main
      padding-top 2px
    .icon
      cursor pointer

.size-select
  display flex
  .size-select__item
    padding 0 $space-2
    border-radius $border-radius
    font-size $fs-h3
    cursor pointer
    &:hover, &--selected
      background-color white
      color black
</style>