<template>
  <div class="row flex htree-container chart-tree">
    <div class="htree row flex"
         :class="{draggable: dragEnabled}"
         @mousedown="onDragStart"
         @mouseup="onDragEnd"
         @mousemove="onMouseMove"
         @click="onClickOutside"
         @mousewheel="onMouseWheel"
         @DOMMouseScroll="onMouseWheel"
         @onmousewheel="onMouseWheel">
      <div ref="panTarget"
           class="pan-target"
           :class="{animated: !dragEnabled}"
           :style="panTargetStyle">
        <ul>
          <tree-node v-if="nodes"
                     :node="nodes"
                     :onClick="onNodeSelected"
                     :onNodeToggle="onNodeToggled"
                     :level="0"
                     :parentOpen="true"
                     :nodeClass="nodeClass"
                     :currentPath="currentPath"
                     :onDestroyNode="onDestroyNode"
                     :parent="null">
          </tree-node>
        </ul>
      </div>
    </div>
    <tree-controls ref="controls"
                   :onSizeChange="onSizeChanged"
                   :onDragToggle="onDragToggled"
                   @center="centerTree" />
  </div>
</template>

<script>
import TreeNode from '@/app/components/topology/tree-node.vue';
import TreeControls from '@/app/components/topology/tree-controls.vue';
import { EventBus } from 'src/event-bus.js';

const drag = {
  x: 0,
  y: 0,
  state: false,
};
const delta = {
  x: 0,
  y: 0,
};
let isClick = true;

export default {
  components: { TreeNode, TreeControls },
  props: {
    nodes: {
      type: Object,
      default: () => ({}),
    },
    onDestroyNode: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedNode: null,
      offset: {
        left: 0,
        top: 20,
      },
      offsetsByNodeClasses: {
        nxs: 140,
        nsm: 190,
        nmd: 190,
      },
      currentLevel: 0,
      nodeClass: null,
      nodeClasses: ['nxs', 'nsm', 'nmd'],
      dragEnabled: null,
      currentPath: null,
      resizeTimeout: null,
    };
  },
  computed: {
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    panTargetStyle() {
      return {
        top: `${this.offset.top}px`,
        left: `${this.offset.left}px`,
      };
    },
  },
  watch: {
    nodeClass: function(newNodeClass) {
      this.setLeftOffset(this.currentLevel, newNodeClass);
    },
    dragEnabled: function(newDragValue) {
      if (!newDragValue) this.setLeftOffset(this.currentLevel, this.nodeClass);
    },
  },
  created() {
    EventBus.$on('node-drag-start', () => {
      this.onDragEnd();
    });
  },
  mounted() {
    this.centerTree();
  },
  methods: {
    centerTree() {
      const panTarget = this.$refs.panTarget;
      const treeViewport = panTarget.parentElement;
      this.offset.left = treeViewport.offsetWidth / 2 - panTarget.offsetWidth / 2;
      this.offset.top = treeViewport.offsetHeight / 2 - panTarget.offsetHeight / 2;
    },
    openPath(path) {
      this.currentPath = path;
    },
    onNodeSelected(selectedNode) {
      this.$store.commit('topology/setSelectedNode', selectedNode);
    },
    onNodeToggled(node, value, level) {
      // console.log(`${node.name} toggled ${value} at level ${level}`);
      this.currentLevel = level;
      this.setLeftOffset(level, this.nodeClass);
    },
    setLeftOffset(level, nodeClass) {
      if (!this.dragEnabled) {
        this.offset.top = 20;
        this.offset.left = -(level < 1 ? 0 : level - 1) * this.offsetsByNodeClasses[nodeClass];
      }
    },
    onDragStart(e) {
      if (!this.dragEnabled) return;

      isClick = true;
      if (!drag.state && e.which === 1) {
        drag.x = e.pageX;
        drag.y = e.pageY;
        drag.state = true;
      }
      return false;
    },
    onMouseMove(e) {
      if (!this.dragEnabled) return;

      isClick = false;
      if (drag.state) {
        delta.x = e.pageX - drag.x;
        delta.y = e.pageY - drag.y;

        this.offset.left += delta.x;
        this.offset.top += delta.y;

        drag.x = e.pageX;
        drag.y = e.pageY;
      }
    },
    onDragEnd() {
      if (!this.dragEnabled) return;

      if (drag.state) {
        drag.state = false;
      }
    },
    onClickOutside() {
      if (!isClick) {
        return;
      }
      this.onNodeSelected(null);
    },
    onSizeChanged(size) {
      this.nodeClass = this.nodeClasses[size];
    },
    onDragToggled(val) {
      this.dragEnabled = val;
    },
    centerToMouse(e) {
      const panTarget = this.$refs.panTarget;
      //        console.log('==== Dezoom ====');
      let mouseX = e.pageX;
      let mouseY = e.pageY;
      //        console.log('Mouse is at position ' + mouseX + ',' + mouseY);
      const targetWidth = panTarget.offsetWidth;
      const targetHeight = panTarget.offsetHeight;
      //        console.log('Target has size ' + targetWidth + ',' + targetHeight);
      const offsetLeft = panTarget.offsetLeft;
      const offsetTop = panTarget.offsetTop + 118; // Add headers height
      //        console.log('Target has offset ' + offsetLeft + ',' + offsetTop);
      mouseX -= offsetLeft;
      mouseY -= offsetTop;
      //        console.log('Mouse is at position over target ' + mouseX + ',' + mouseY);
      const percentWidth = mouseX / targetWidth;
      const percentHeight = mouseY / targetHeight;
      //        console.log('Mouse is at percentage ' + percentWidth*100 + '%,' + percentHeight*100 + '%');

      if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        //          console.log('==== Center to mouse ====');
        const newTargetWidth = panTarget.offsetWidth;
        const newTargetHeight = panTarget.offsetHeight;
        //          console.log('Target has new size ' + newTargetWidth + ',' + newTargetHeight);
        const newMouseX = newTargetWidth * percentWidth;
        const newMouseY = newTargetHeight * percentHeight;
        //          console.log('Mouse should be at ' + newMouseX + ',' + newMouseY);
        const mouseOffsetX = newMouseX - mouseX;
        const mouseOffsetY = newMouseY - mouseY;
        //          console.log(mouseOffsetX);
        //          console.log(mouseOffsetY);
        this.offset.left -= mouseOffsetX;
        this.offset.top -= mouseOffsetY;
      }, 0);
    },
    onMouseWheel(e) {
      // cross-browser wheel delta
      e = window.event || e; // old IE support
      const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));

      if (delta < 0) {
        if (this.dragEnabled) {
          this.centerToMouse(e);
        }
        this.$refs.controls.decreaseSize();
      } else {
        if (this.dragEnabled) {
          this.centerToMouse(e);
        }
        this.$refs.controls.increaseSize();
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~variables'

$node-separation ?= $space-2
$edge_color ?= black

.htree-container
  position relative
  width 100%
  height 100%
  .tree-controls
    position absolute
    top $space-2
    right $space-2

.htree
  position relative
  overflow hidden
  overflow-y auto
  width 100%

.htree.draggable
  overflow-y hidden

.htree.draggable:hover
  cursor grab

.pan-target.animated
  transition all 0.5s

.pan-target
  position absolute

.htree ul, .htree li
  margin 0
  padding 0

.htree ul
  position relative
  /* transition: all 0.5s; */
  display flex
  flex-direction column
  padding-left $node-separation

.htree li
  position relative
  padding 1px 0 1px $node-separation
  list-style-type none
  /* transition: all 0.5s; */

/* We will use ::before and ::after to draw the connectors */
.htree li::before, .htree li::after
  position absolute
  top 0
  left 0
  width $node-separation
  height 50%
  border-left 1px solid $edge_color
  content ''

.htree li::before
  border-bottom 1px solid $edge_color

.htree li::after
  top 50%
  bottom auto

/* We need to remove left-right connectors from elements without
any siblings */
.htree li:only-child::after, .htree li:only-child::before
  display none

/* Remove space from the top of single children */
.htree li:only-child
  /* padding-left: 0; */

/* Remove left connector from first child and
right connector from last child */
.htree li:first-child::before, .htree li:last-child::after
  border 0 none

/* Adding back the vertical connector to the last nodes */
.htree li:last-child::before
  border-bottom 1px solid $edge_color
  border-radius 0 0 0 $border-radius-smooth

.htree li:first-child::after
  border-top 1px solid $edge_color
  border-radius $border-radius-smooth 0 0 0

.htree li:only-child::after
  display inherit
  border-top 1px solid $edge_color !important
  border-radius 0

/* Time to add downward connectors from parents */
.htree ul ul::before
  position absolute
  top 50%
  left 0
  width $node-separation
  height 0
  border-top 1px solid $edge_color
  content ''

/* Time for some hover effects */
/* We will apply the hover effect the lineage of the element also */
.htree li .node:hover, .htree li .node:hover+ul li .node
  border-color $secondary
  text-decoration none !important
  cursor pointer

/* Connector styles on hover */
.htree li .node:hover+ul li::after, .htree li .node:hover+u, .htree ul:not(.md-list)>li+li
  margin 0
</style>